/* You can add global styles to this file, and also import other style files */
button:hover,
button:focus,
:focus {
    outline: none!important;
    text-decoration: none!important;
}
.aside-open.sigma_aside {
    left: 0;
}
.aside-open.sigma_aside:not(.sigma_aside-desktop)+.sigma_aside-overlay, 
.aside-right-open.sigma_aside-desktop+.sigma_aside-overlay {
    opacity: 1;
    visibility: visible;
}
.sigma_about-content .sigma_info:last-child{
    margin-bottom: 0;
}
.sigma_testimonial .sigma_testimonial-thumb img{
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.sigma_post.post-format-quote blockquote + .sigma_post-body,
.sigma_post.post-format-link .sigma_post-body + .sigma_post-body{
	display: none;
}
.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-image img{
	width: 80px;
	height: 80px;
	object-fit: cover;
}
.sigma_post-details.style-6 .comments-list .comment-item:last-child {
    margin-left: 0px;
}
.sigma_post-details.style-6 .comments-list .comment-item + .comment-item{
	margin-left: 70px;
	margin-bottom: 0;
}
.comments-list ul{
	margin-bottom: 30px;
}
.comments-list ul + ul{
	margin-bottom: 0px;
}
.accordion{
	position: relative;
	display: block;
}
.relative {
    position: relative;
}
.error-texts h1 {
    font-size: 133px;
    font-weight: 900;
    margin-bottom: 20px;
}
.error-texts h3{
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 20px;
}
.error-texts p{
    font-size: 16px;
    margin-bottom: 20px;
}
.sigma_team .sigma_team-controls a.active {
    background-color: var(--thm-base);
    color: #fff;
}
/* Pagination */
pagination-template .ngx-pagination {
	margin: 60px 0 0rem;
	justify-content: center;
	display: flex;
	padding-left: 0;
	list-style: none;
    flex-wrap: wrap;
}
pagination-template .ngx-pagination li {
	margin: 0;
	position: relative;
    margin-bottom: 15px!important;
}
pagination-template .ngx-pagination li.pagination-next, pagination-template .ngx-pagination li.pagination-previous {
	padding: 0;
	display: block;
}
pagination-template .ngx-pagination li.pagination-next span, pagination-template .ngx-pagination li.pagination-previous span, pagination-template .ngx-pagination li a {
	color: var(--thm-secondary);
	border: 0px solid rgba( var(--thm-base-rgb), .1 );
	background-color: rgba( var(--thm-base-rgb), .1 );
	font-size: 14px;
	font-weight: 600;
    padding: 13px 20px !important;
	position: relative;
	margin-left: 15px;
	display: block;
	line-height: 1.5;
    border-radius: 50%;
}
pagination-template .ngx-pagination li.current {
	color: #fff;
	border: 0px solid var(--thm-base);
	background-color: var(--thm-base); 
	font-size: 14px;
	font-weight: 600;
    padding: 13px 20px !important;
	position: relative;
	margin-left: 15px;
	display: block;
	line-height: 1.5;
    border-radius: 50%;
    box-shadow: 0 5px 6px rgb(0 0 0 / 18%);
}
pagination-template .ngx-pagination li.pagination-next::after, pagination-template .ngx-pagination li.pagination-next a:after, pagination-template .ngx-pagination li.pagination-previous a::before, pagination-template .ngx-pagination li.pagination-previous::before {
	display: none!important;
}
pagination-template .ngx-pagination li.pagination-previous a,
pagination-template .ngx-pagination li.pagination-previous span{
    margin: 0;
}
@media (max-width: 600px){
    pagination-template .ngx-pagination{
        justify-content: center;
        margin-top: 20px;
    }
    .ngx-pagination.responsive .small-screen{
        display: none!important;
    }
    .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
        display: block!important;
    }
}
/* Range slider */
.ngx-slider .ngx-slider-bubble{
    padding: 1px 5px!important;
    color: #ffffff!important;
    font-size: 10px!important;
    background-color: var(--thm-base);
    border-radius: 4px;
}
.ngx-slider .ngx-slider-bubble::after{
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: var(--thm-base);
}
.ngx-slider .ngx-slider-bar{
    background: #e1e4e9!important;
    height: 5px!important;
    border-radius: 4px!important;
}
.ngx-slider .ngx-slider-selection{
    background: var(--thm-base)!important;
    border-radius: 0px!important;
}
.ngx-slider .ngx-slider-pointer:after {
    display: none!important;
}
.ngx-slider .ngx-slider-pointer{
    width: 20px!important;
    height: 20px!important;
    top: -8px!important;
    border: 2px solid var(--thm-base);
    background-color: #fff!important;
}
.sigma_info-wrapper.style-26{
    flex-wrap: unset;
}
.service-details img{
    width: 100%;
}
.sigma_progress.round.sm img {
    width: 100px;
    height: 100px;
    margin-bottom: 0;
}

.sigma_progress.round>img {
    width: 120px;
    height: 120px;
    overflow: visible;
}
.gallery-thumb{
    display: block;
}
.gallery-thumb img{
    width: 100%;
}
.card .card-header .btn{
    white-space: normal;
}
.card .card-header .btn:after{
    margin-left: 20px;
}
.sigma_clients.style-3{
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.sigma_post.style-7 .sigma_post-body{
    padding-bottom: 15px;
}
.sigma_post .sigma_post-meta ul li{
    margin-bottom: 15px;
}
.sigma_post.style-7 .sigma_post-meta ul li+li, .sigma_post.style-8 .sigma_post-meta ul li+li{
    margin-left: 0;
}
.sigma_post.style-7 .sigma_post-meta ul li, 
.sigma_post.style-8 .sigma_post-meta ul li{
    margin-right: 20px;
}
.sigma_post.style-7 .sigma_post-meta ul li:last-child, 
.sigma_post.style-8 .sigma_post-meta ul li:last-child{
    margin-right: 0px;
}
.sigma_post .sigma_post-meta ul li{
    margin-right: 20px;
}
.sigma_post.style-11 .sigma_post-meta ul li:last-child, 
.sigma_post.style-12 .sigma_post-meta ul li:last-child, 
.sigma_post.style-15 .sigma_post-meta ul li:last-child{
    margin-right: 0;
}
.sigma_post.style-11 .sigma_post-meta ul li + li, 
.sigma_post.style-12 .sigma_post-meta ul li + li, 
.sigma_post.style-15 .sigma_post-meta ul li + li{
    margin-left: 0;
}
.sigma_post-details.style-6 .sigma_post-details-meta{
    margin-bottom: 0;
}
.sigma_post-details.style-6 .sigma_post-details-meta span{
    margin-bottom: 12px;
}
@media (max-width:991px) {
    .sigma_info-wrapper.style-26{
        flex-wrap: wrap;
    }
    .mb-md-80{
        margin-bottom: 80px;
    }
}
@media (max-width:575px){
    .sigma_about.style-21 .sigma_about-image-1{
        max-width: 100%;
    }
    .sigma_about.style-21 .sigma_about-image-1 img{
        width: 100%;
    }
    .sigma_team.style-17 .sigma_rating{
        margin-top: 30px;
    }
    .sigma_testimonial.style-14 .sigma_testimonial-body{
        margin-top: 0;
    }
}